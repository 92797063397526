<template>
  <div class="impressie">
    <Gallery />
  </div>
</template>
<script>
import Gallery from '@/components/Impressie/gallery.vue';

export default {
  name: 'impressie',
  components: {
    Gallery,
  },
};
</script>
