<template>
    <silentbox-group class=".flex-container">
        <silentbox-item src="@/assets/images/gallery/Image_01.jpg" description="">
            <img class="image01" src="@/assets/images/gallery/Image_01.jpg">
        </silentbox-item>
        <silentbox-item src="@/assets/images/gallery/Image_02.jpg" description="">
            <img class="image02" src="@/assets/images/gallery/Image_02.jpg">
        </silentbox-item>
          <silentbox-item src="@/assets/images/gallery/Image_03.jpg" description="">
            <img class="image03" src="@/assets/images/gallery/Image_03.jpg">
        </silentbox-item>
        <silentbox-item src="@/assets/images/gallery/Image_04.jpg" description="">
            <img class="image04" src="@/assets/images/gallery/Image_04.jpg" width="200px">
        </silentbox-item>
        <silentbox-item src="@/assets/images/gallery/Image_05.jpg" description="">
            <img class="image05" src="@/assets/images/gallery/Image_05.jpg" width="200px">
        </silentbox-item>
        <silentbox-item src="@/assets/images/gallery/Image_06.jpg" description="">
            <img class="image06" src="@/assets/images/gallery/Image_06.jpg" width="200px">
        </silentbox-item>
        <silentbox-item src="@/assets/images/gallery/Image_07.jpg" description="">
            <img class="image07" src="@/assets/images/gallery/Image_07.jpg" width="200px">
        </silentbox-item>
        <silentbox-item src="@/assets/images/gallery/Image_08.jpg" description="">
            <img class="image08" src="@/assets/images/gallery/Image_08.jpg" width="200px">
        </silentbox-item>
    </silentbox-group>
</template>
<script>
export default {

};

</script>


<style lang="less" scoped>
.flex-container{
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
}
.image01,
.image02,
.image03,
.image04,
.image05,
.image06,
.image07,
.image08{
  padding: 5px;
  width: auto;
  height: auto;
  max-height:400px;
  margin-top: 10px;
  line-height: 150px;
  color: white;
  font-weight: bold;
  font-size: 3em;
  text-align: center;
}
@media screen {
  @media (max-width:600px) {
    .image02{ width: 450px;}
    .image04{ width: 450px;}
    .image05{ width: 450px;}
    .image08{ width: 450px;}
  }
  @media (max-width: 450px) {
    .image02{ width: 250px;}
    .image03{ width: 250px;}
    .image04{ width: 250px;}
    .image05{ width: 250px;}
    .image08{ width: 250px;}
  }
}

</style>
